import * as React from "react";
// import { Link } from "gatsby"

import Layout from "../components/layout";
import HeroSection from "../components/OurServices/HeroSection";
import Loading from "../components/OurServices/Loading";
import Seo from "../components/seo";

const OurServices = () => (
  <Layout>
    <Seo title="Our Services" />
    <HeroSection />
    <Loading />
  </Layout>
);

export default OurServices;
